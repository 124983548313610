import { batch } from 'react-redux';
import { Dispatch } from 'redux';
import { getAllKioskData } from '../../libs/kioskLib';
import { Kiosk, MobileDevice, PatientForKiosk, Session } from '../../Types/Common/KioskTypes';
import { OrganizationType } from '../../Types/Common/Types';
import { ADD_PATIENT_TO_KIOSK, CLEAR_KIOSK_DATA, CLEAR_SELECTED_PATIENT, EDIT_PATIENT_FOR_KIOSK, SET_IN_SESSION, SET_KIOSK, SET_KIOSK_MODE, SET_MOBILE_DEVICE, SET_PATIENTS_FOR_KIOSK, SET_SELECTED_ORGANIZATION, SET_SELECTED_PATIENT, SET_SESSIONS, SET_SPIROMETER_CONNECTED, SET_RESET_BLUETOOTH_COMPLETED } from './actionTypes';

export const setKiosk = (payload: Kiosk) => ({ payload, type: SET_KIOSK });

export const setMobileDevice = (payload?: MobileDevice) => ({ payload, type: SET_MOBILE_DEVICE });

export const setKioskPatients = (payload: Record<string, PatientForKiosk>) => ({ payload, type: SET_PATIENTS_FOR_KIOSK });

export const editKioskPatient = (payload: PatientForKiosk) => ({ payload, type: EDIT_PATIENT_FOR_KIOSK });

export const addPatientToKiosk = (payload: PatientForKiosk) => ({ payload, type: ADD_PATIENT_TO_KIOSK });

export const setKioskSessions = (payload: Session[]) => ({ payload, type: SET_SESSIONS });

export const setIsKioskMode = (payload: boolean) => ({ payload, type: SET_KIOSK_MODE });

export const setIsInSession = (payload: boolean) => ({ payload, type: SET_IN_SESSION });

export const setSelectedPatient = (payload: PatientForKiosk) => ({ payload, type: SET_SELECTED_PATIENT });

export const clearSelectedPatient = () => ({ type: CLEAR_SELECTED_PATIENT });

export const setSelectedOrganization = (payload: OrganizationType) => ({ payload, type: SET_SELECTED_ORGANIZATION });

export const setIsSpirometerConnected = (payload: boolean) => ({ payload, type: SET_SPIROMETER_CONNECTED });

export const setResetBluetoothCompleted = (payload: boolean) => ({ payload, type: SET_RESET_BLUETOOTH_COMPLETED });

export const fetchAllKioskData = (kioskID: string) => {
  return async (dispatch: Dispatch) => {
    const { kiosk, patients, sessions } = await getAllKioskData(kioskID);
    batch(() => {
      dispatch(setKiosk(kiosk));
      dispatch(setKioskPatients(patients));
      dispatch(setKioskSessions(sessions));
    });
  };
};

export const clearKioskData = () => ({ type: CLEAR_KIOSK_DATA });